<template>
<div>
    <v-container>
        <v-row>
            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Reporte Ventas" class="tran"></s-toolbar>
                <v-card>

                    <v-row justify="center" style="margin-top: 4px; margin-left: 10px; margin: auto">
                        <v-col cols="12" lg="3" md="3">
                            <s-select-definition :def="1142" v-model="objType" return-object label="Empresa"></s-select-definition>
                        </v-col>
                        <v-col cols="6" lg="4" md="4">
                            <s-select-definition :def="1131" v-model="objMounth" return-object label="Seleccione mes"></s-select-definition>
                        </v-col>
                        <v-col cols="6" lg="2" md="3">
                            <s-text label="Ingrese año" number v-model="txtYear"></s-text>
                        </v-col>

                    </v-row>

                    <v-row style="margin-left: auto; margin-left: 30px; margin-top: -20px">

                        <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                            <v-btn width="100%" outlined rounded :color="'info'" small @click="generated()"><i style="font-size: 20px" class="fas fa-spinner"></i> Generar</v-btn>
                        </v-col>
                        <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                            <v-btn width="100%" outlined rounded :color="'warning'" small @click="runView()"><i style="font-size: 20px" class="fas fa-eye"></i> Visualizar</v-btn>
                        </v-col>
                        <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                            <v-btn width="100%" outlined rounded :color="'success'" small @click="downloadReport()"><i style="font-size: 20px" class="fas fa-file-pdf"></i> Exportar</v-btn>
                        </v-col>

                    </v-row>

                </v-card>
            </v-col>
        </v-row>

        <v-card flat>
            <v-expand-transition>
                <div>
                    <v-divider></v-divider>
                    <v-row justify="center" style="">

                        <v-col cols="12" lg="12" md="12" class="mt-3 ml-5">
                            <v-data-table disable-sort :height="items.length > 0 ? '400px': 'auto'" :headers="headers" :items="items" dense :footer-props="{
                                    showCurrentPage: true,
                                    showFirstLastPage: true,
                                    itemsPerPageOptions: [5, 10, 50, 100],
                                }">
                            </v-data-table>
                        </v-col>

                    </v-row>

                </div>
            </v-expand-transition>

        </v-card>

        <v-dialog v-model="processing" v-if="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Esto puede tardar unos minutos, Por favor espere.
                    <v-progress-linear indeterminate color="white" class="mb-0 mt-3"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al generar asientos contables <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</div>
</template>

<script>
import _sAccounting from '@/services/Accounting/ConChargeMasive.js'
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
export default {
    props: {

    },
    components: {},
    data() {
        return {
            show: true,
            processing: false,
            messageProcessing: "",

            items: [],
            headers: [{
                text: "",
                value: ""
            }],

            objMounth: null,
            objType: null,
            txtYear: this.$fun.getYear(),
            report: {},
        };
    },

    methods: {

        generated() {
            var obj = {
                Mounth: this.objMounth.DedHelper,
                Year: this.txtYear,
                TypeName: this.objType.DedDescription

            }

            
            this.messageProcessing = ""

            this.$fun.alert("Seguro de generar reporte de ventas ? " + this.objType.DedDescription, "question").then(val => {
                if (val.value) {
                    this.processing = true;
                    _sAccounting.generatedReportSales(obj, this.$fun.getUserID()).then(resp => {
                            if (resp.status == 200) {
                                this.processing = false;
                                this.$fun.alert("Reporte generado correctamente", "success")
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        })
                }
            })
        },

        runView() {

            this.messageProcessing = ""
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "Mounth, Year, TypeName"
            this.report.QryParamsArrayValue = this.objMounth.DedHelper + "," + this.txtYear + ', ' + this.objType.DedDescription //this.DateBegin + "," + this.DateEnd + "," + this.number;
            this.report.QryNameProcedure = "ConGeneratedSalesPrint_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(this.report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.items = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.items[0]).length; i++
                            ) {
                                this.headers.splice(i, i, {
                                    text: Object.keys(this.items[0])[i],
                                    value: Object.keys(this.items[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        downloadReport() {

            var name = "";
            this.messageProcessing = ""
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "Mounth, Year, TypeName"
            this.report.QryParamsArrayValue = this.objMounth.DedHelper + "," + this.txtYear + ', ' + this.objType.DedDescription //this.DateBegin + "," + this.DateEnd + "," + this.number;

            this.report.QryNameProcedure = "ConGeneratedSalesPrint_R";
            name = this.objType.DedDescription

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(this.report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                name
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
